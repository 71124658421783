import React, {Fragment, useEffect} from 'react';
import {Button, Form, Input, Radio} from 'antd';
import AddressField from './AddressField';
import styled from 'styled-components';

const appConfig = require('../../data.json');

export default function CustomerInfo({config: _config, setConfig}) {
  const config = _config.userConfig;
  const form = Form.useFormInstance();
  useEffect(() => {
    form.setFieldsValue({
      name: config.name,
      phone: config.phone,
      email: config.email,
      address: config.address,
      city: config.city,
      district: config.district,
      zip_code: config.zip_code,
    });
  }, [form, config]);
  return (
    <Fragment>
      <StyledFormItem label="顧客名稱" name="name">
        <Input placeholder="顧客名稱" />
      </StyledFormItem>
      <StyledFormItem label="手機號碼" name="phone">
        <Input placeholder="手機號碼" />
      </StyledFormItem>
      <StyledFormItem label="通訊地址">
        <AddressField
          zip_code={config.zip_code}
          city={config.city}
          district={config.district}
          address={config.address}
        />
      </StyledFormItem>
      <StyledFormItem label="電子信箱" name="email">
        <Input placeholder="電子信箱" />
      </StyledFormItem>
    </Fragment>
  );
}

const StyledFormItem = styled(Form.Item)`
  .ant-form-item-label > label {
    color: #505050;
  }
  input {
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 2px;
    padding: 10px 12px;
    font-size: 14px;
    color: #505050;
  }
  .ant-radio-wrapper {
    font-size: 14px;
    color: #505050;
  }

  .ant-form-item {
    margin-bottom: 14px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

import React, {
  useContext,
  Fragment,
  useState,
  useCallback,
  useEffect,
} from 'react';
import styled from 'styled-components';
import PaymentMethods from '../../Components/Checkout/PaymentMethods';
import {Row, Col, Input, Button, Select, message, Checkbox} from 'antd';
import {Expander, RowText, Divider} from '../../Widgets';
import Block from '../../Components/Checkout/Block';
import PaymentDetails from '../../Components/Checkout/PaymentDetails';
import {formatValidator} from '../../Utils';
import CheckoutDetail from '../../Modals/CheckoutDetail';
import CheckoutAgreement from '../../Modals/CheckoutAgreement';
import {
  INVOICE_TYPES,
  DELIVERY_TYPE,
  TWO_COPIES_TYPES,
  LOVECODE_OPTIONS,
  STORE_CHANNEL,
} from '../../dictionary';
import {Context} from '../../AppContext';
import CartItemTable from '../../Components/CartItemTable';
import OrderReminder from '../../Modals/OrderReminder';
// import {isVIP, isMonthly} from '../../Utils/UserUtil';
import {ErrCheckoutForm, ErrInvoiceFormat} from '../../errors';
import invoiceValidator from '../../Utils/invoice-validator';
import {
  disabledSuperMarket,
  disabledSuperMarketItems,
} from '../../Utils/LogisticUtil';
import useDimension from '../../hooks/use-dimension';

const appConfig = require('../../data.json');
const htmls = require('../../content-html.json');

const {isNotEmpty, isEmail, isMobileNumber, isExceedStringMaxLength} =
  formatValidator;

export default function CheckoutNew({
  config,
  setConfig,
  params,
  setParams,
  updateConfig,
  createOrder,
}) {
  const app = useContext(Context);
  const {cart} = app.state;
  const [currentEditModal, setCurrentEditModal] = useState(null);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [reminderModal, setReminderModal] = useState(false);
  const [logistics, setLogistics] = useState([]);
  const [currentAgreementModal, setCurrentAgreementModal] = useState(null);
  const [disabledSuperMarketValue, setDisabledSuperMarketValue] =
    useState(true);
  const {dimension} = useDimension();

  let {userConfig, deliveryConfig, invoiceConfig} = config;

  const hasUserInfo = Object.values(userConfig).some((value) => value);
  const hasShippingInfo = Object.values(deliveryConfig).some((value) => value);
  const hasInvoiceInfo = Object.values(invoiceConfig).some((value) => value);

  useEffect(() => {
    const fetchLogisticsData = async () => {
      try {
        const {results} = await app.actions.fetchLogisticsAddress();
        setLogistics(results);

        // 如果有物流資料，更新 deliveryConfig
        if (results && results.length > 0) {
          setConfig('delivery', {
            receiver_name: results[0].name,
            receiver_phone: results[0].phone,
            receiver_city: results[0].city,
            receiver_district: results[0].district,
            receiver_address: results[0].address,
            zip_code: results[0].zip_code,
            note: results[0].note,
            // 保留原有的 delivery_type，如果沒有則設置一個默認值
            delivery_type:
              deliveryConfig.delivery_type || appConfig.supportLogistics[0],
          });
        }
      } catch (err) {
        console.warn('Error fetching logistics data:', err);
        setLogistics([]);
      }
    };

    fetchLogisticsData();
  }, [app.actions]);

  useEffect(() => {
    const checkDisabledSuperMarket = async () => {
      const result = await disabledSuperMarket(
        cart,
        app.actions,
        app.state.spec,
      );
      setDisabledSuperMarketValue(result);
    };

    checkDisabledSuperMarket();
  }, [cart, app.actions, app.state.spec]);

  const handleOnSubmit = async () => {
    app.actions.setLoading(true);
    try {
      await valid(config);
      // 記住資訊
      let {userConfig} = config;
      app.actions.editProfile({
        address: userConfig.address,
        city: userConfig.city,
        district: userConfig.district,
        name: userConfig.name,
        phone: userConfig.phone,
        zip: userConfig.zip_code,
      });
      await updateConfig();
      await createOrder();
    } catch (err) {
      console.warn(err);

      if (err instanceof ErrInvoiceFormat || err instanceof ErrCheckoutForm) {
        message.error(err.message);
      }
    }
    app.actions.setLoading(false);
  };
  const triggerReminderOnChecked = (value) => {
    if (value) {
      setReminderModal(true);
    }
  };
  const handleReminderOnCancle = (value) => {
    setTermsAgreed(false);
    setReminderModal(false);
  };

  const valid = async (config) => {
    if (!termsAgreed) {
      throw new ErrCheckoutForm('請閱讀且同意注意事項');
    }

    if (!params.payment_type) {
      throw new ErrCheckoutForm('請選擇付款方式');
    }

    const {invoiceConfig, userConfig, deliveryConfig} = config;

    // user config
    let {name, phone, city, district, address, email} = userConfig;
    if (
      !isNotEmpty(name) ||
      !isNotEmpty(city) ||
      !isNotEmpty(district) ||
      !isNotEmpty(address) ||
      !isNotEmpty(email) ||
      !isNotEmpty(phone)
    ) {
      throw new ErrCheckoutForm('請填入會員資料必填欄位');
    }
    if (!isEmail(email)) {
      throw new ErrCheckoutForm('會員資料電子信箱格式錯誤');
    }
    if (!isMobileNumber(phone)) {
      throw new ErrCheckoutForm('會員資料手機格式錯誤');
    }

    if (isExceedStringMaxLength(name)) {
      throw new ErrCheckoutForm('會員資料名稱長度過長');
    }

    if (isExceedStringMaxLength(address)) {
      throw new ErrCheckoutForm('會員資料通訊地址長度過長');
    }

    //delivery config
    let {
      delivery_type,
      is_delivery_private = '',
      receiver_name = '',
      receiver_phone = '',
      receiver_city = null,
      receiver_district = null,
      receiver_address = '',
      sender_name = '',
      sender_phone = '',
      sender_city = null,
      sender_district = null,
      sender_address = '',
    } = deliveryConfig;

    if (
      ['hct', 'mailing', 'tcat', 'kerry_tj', 'special_car'].includes(
        delivery_type,
      )
    ) {
      if (
        !isNotEmpty(receiver_name) ||
        !isNotEmpty(receiver_phone) ||
        !isNotEmpty(receiver_city) ||
        !isNotEmpty(receiver_district) ||
        !isNotEmpty(receiver_address)
      ) {
        throw new ErrCheckoutForm('請填入宅配運送/收件人必填欄位');
      }
      if (!isMobileNumber(receiver_phone)) {
        throw new ErrCheckoutForm('收件人手機格式錯誤');
      }
    } else if (['ezship', 'payuni', 'xdelivery'].includes(delivery_type)) {
      if (!isNotEmpty(receiver_name) || !isNotEmpty(receiver_phone)) {
        throw new ErrCheckoutForm('請填入收件人必填欄位');
      }
      if (!isMobileNumber(receiver_phone)) {
        throw new ErrCheckoutForm('收件人手機格式錯誤');
      }

      if (disabledSuperMarketValue) {
        const disabledItems = await disabledSuperMarketItems(
          cart,
          app.actions,
          app.state.spec,
        );
        const names = disabledItems.map((x) => x.name);
        throw new ErrCheckoutForm(
          `${names.join(
            ', ',
          )}商品僅支援宅配，建議您選擇『 宅配 』物流付款，若仍需以超商取貨，則需分批下單恕無法合併運費計算！`,
        );
      }
    } else {
      // self pick
    }

    if (is_delivery_private) {
      /* 保密代記 */
      if (
        !isNotEmpty(sender_name) ||
        !isNotEmpty(sender_phone) ||
        !isNotEmpty(sender_city) ||
        !isNotEmpty(sender_district) ||
        !isNotEmpty(sender_address)
      ) {
        throw new ErrCheckoutForm('請填入保密代寄/寄件人必填欄位');
      }
      if (!isMobileNumber(sender_phone)) {
        throw new ErrCheckoutForm('保密代寄/寄件人手機格式錯誤');
      }

      if (
        sender_name === receiver_name ||
        sender_phone === receiver_phone ||
        sender_address === receiver_address
      ) {
        throw new ErrCheckoutForm('保密代寄/寄件人資料不可和收件人資料相同');
      }

      if (isExceedStringMaxLength(sender_name)) {
        throw new ErrCheckoutForm('保密代寄/寄件人名長度過長');
      }

      if (isExceedStringMaxLength(sender_address)) {
        throw new ErrCheckoutForm('保密代寄/寄件地址長度過長');
      }
    }

    if (isExceedStringMaxLength(receiver_name)) {
      throw new ErrCheckoutForm('收件人名長度過長');
    }

    if (isExceedStringMaxLength(receiver_address)) {
      throw new ErrCheckoutForm('收件地址長度過長');
    }

    //invoice
    invoiceValidator(invoiceConfig);
  };

  const generateDeliveryTypeOptions = useCallback(
    (disabledSuperMarketValue) => {
      let options = appConfig.supportLogistics.map((type) => ({
        value: type,
        label: DELIVERY_TYPE[type],
        disabled:
          ['payuni', 'xdelivery'].includes(type) && disabledSuperMarketValue,
      }));

      if (appConfig.supportLogistics.includes('hct')) {
        options.push({
          value: 'hct-private',
          label: '宅配 - 保密代寄',
        });
      }

      return options;
    },
    [appConfig.supportLogistics, disabledSuperMarketValue],
  );

  return (
    <Fragment>
      <Row gutter={[20, 0]}>
        <Col flex="100%">
          <Wrap
            css={{
              '@media (max-width: 768px)': {
                padding: '0',
              },
            }}>
            <Expander
              expand={false}
              title={`總計共 ${cart.items.length} 個品項`}
              containerStyle={{marginBottom: 0}}
              titleStyle={{textAlign: 'center'}}
              containerMobileStyle={{justifyContent: 'center !important'}}
              titleMobileStyle={{flex: '0 0 auto !important'}}>
              <CartItemTable viewOnly={true} />
            </Expander>
          </Wrap>
        </Col>
        <Col flex="1">
          <Block title={'訂購人資訊'}>
            {hasUserInfo && (
              <InfoBox>
                <h3>訂購人資訊</h3>
                <RowText
                  weight="400"
                  label="顧客名稱"
                  value={userConfig.name}
                />
                <RowText
                  weight="400"
                  label="手機號碼"
                  value={userConfig.phone}
                />
                <RowText
                  weight="400"
                  label="電子信箱"
                  value={userConfig.email}
                />
                <RowText
                  weight="400"
                  label="通訊地址"
                  value={`${userConfig.zip_code || userConfig.zip || ''} ${
                    userConfig.city || ''
                  }${userConfig.district || ''}${userConfig.address || ''}`}
                />
              </InfoBox>
            )}
            <StyledButton onClick={() => setCurrentEditModal('customer')}>
              {hasUserInfo ? '編輯訂購人資訊' : '新增訂購人資訊'}
            </StyledButton>
          </Block>
          <Block title={'收件方式'}>
            <StyledSelectContainer>
              <StyledSelect
                size="large"
                bordered={false}
                placeholder="選擇收件方式"
                defaultValue={deliveryConfig.delivery_type}
                onChange={(value) => {
                  if (['ezship', 'payuni', 'xdelivery'].includes(value)) {
                    const orginalWebsiteUrl = new URL(
                      `${appConfig.siteUrl}/cart`,
                    );
                    orginalWebsiteUrl.searchParams.append('step', '2');
                    const url =
                      value === 'ezship'
                        ? app.actions.getCvsUrl()
                        : value === 'payuni'
                        ? app.actions.getPayuniShipMap(
                            dimension.innerWidth < appConfig.breakpoints.lg,
                            encodeURIComponent(orginalWebsiteUrl),
                          )
                        : app.actions.getXdeliveryShipMap(
                            encodeURIComponent(orginalWebsiteUrl),
                          );
                    window.open(url, '_self');
                    return;
                  }
                  if (value === 'hct-private') {
                    setConfig('delivery', {
                      delivery_type: 'hct',
                      is_delivery_private: true,
                    });
                  } else {
                    setConfig('delivery', {
                      delivery_type: value,
                      is_delivery_private: false,
                    });
                  }
                }}
                options={generateDeliveryTypeOptions(disabledSuperMarketValue)}
              />
              {deliveryConfig.delivery_type &&
                deliveryConfig.delivery_type !== 'self_pick' && (
                  <StyledButton
                    onClick={() => setCurrentEditModal('shipping')}
                    style={{marginLeft: '10px'}}>
                    編輯收件資訊
                  </StyledButton>
                )}
            </StyledSelectContainer>
            {hasShippingInfo && deliveryConfig.delivery_type === 'hct' && (
              <InfoBox>
                {deliveryConfig.is_delivery_private && (
                  <Fragment>
                    <h3>寄件人資訊</h3>{' '}
                    <RowText
                      weight="400"
                      label="寄件人姓名"
                      value={deliveryConfig.sender_name}
                    />
                    <RowText
                      weight="400"
                      label="寄件人行動電話"
                      value={deliveryConfig.sender_phone}
                    />
                    <RowText
                      weight="400"
                      label="寄件人收件地址"
                      value={`${deliveryConfig.sender_zip ?? ''} ${
                        deliveryConfig.sender_city ?? ''
                      }${deliveryConfig.sender_district ?? ''}${
                        deliveryConfig.sender_address ?? ''
                      }`}
                    />
                  </Fragment>
                )}
                <h3>收件人資訊</h3>
                <RowText
                  weight="400"
                  label="收件人姓名"
                  value={deliveryConfig.receiver_name}
                />
                <RowText
                  weight="400"
                  label="行動電話"
                  value={deliveryConfig.receiver_phone}
                />
                <RowText
                  weight="400"
                  label="收件地址"
                  value={`${deliveryConfig.zip_code ?? ''} ${
                    deliveryConfig.receiver_city ?? ''
                  }${deliveryConfig.receiver_district ?? ''}${
                    deliveryConfig.receiver_address ?? ''
                  }`}
                />
                <RowText
                  weight="400"
                  label="收件備註"
                  value={deliveryConfig.delivery_note}
                />
              </InfoBox>
            )}
            {deliveryConfig.delivery_type === 'self_pick' && (
              <div
                dangerouslySetInnerHTML={{__html: htmls.checkout_self_pick}}
              />
            )}
            {hasShippingInfo &&
              ['ezship', 'payuni', 'xdelivery'].includes(
                deliveryConfig.delivery_type,
              ) && (
                <InfoBox>
                  <h3>門市資料</h3>
                  <RowText
                    weight="400"
                    label="通路"
                    value={STORE_CHANNEL[deliveryConfig.st_state]}
                  />
                  <RowText
                    weight="400"
                    label="門市名稱"
                    value={deliveryConfig.rstore_name}
                  />
                  <RowText
                    weight="400"
                    label="門市地址"
                    value={deliveryConfig.rstore_addr}
                  />
                </InfoBox>
              )}
            <Divider color="#ccc" />
            <Tips>
              ＊未避免造成無法取件之情形，貨件上的取件人姓名需與證件本名相同。
            </Tips>
          </Block>
          <Block title={'付款方式'}>
            <PaymentMethods setParams={setParams} />
            <Tips>
              ＊請於付款方式截止日前進行繳費作業，若訂單已過付款期限，系統將自動取消訂單。未付款訂單保留七日後系統會自動取消，訂單優惠不保留扣抵紅利不退還，請注意您的權益，若需再次交易請重新下單。
            </Tips>
          </Block>
          <Block title={'發票開立'}>
            <StyledSelectContainer>
              <StyledSelect
                size="large"
                bordered={false}
                placeholder="選擇發票開立方式"
                defaultValue={invoiceConfig.invoice_type}
                onChange={(value) => {
                  setConfig('invoice', {invoice_type: value});
                }}
                options={Object.values(INVOICE_TYPES).map((type) => ({
                  value: type.value,
                  label: type.label,
                }))}
              />
              {invoiceConfig.invoice_type && (
                <StyledButton
                  onClick={() => setCurrentEditModal('invoice')}
                  style={{marginLeft: '10px'}}>
                  編輯發票資訊
                </StyledButton>
              )}
            </StyledSelectContainer>
            {hasInvoiceInfo && invoiceConfig.invoice_type === 'two_copies' && (
              <InfoBox>
                <h3>發票資訊</h3>
                <RowText
                  weight="400"
                  label="發票類型"
                  value={
                    TWO_COPIES_TYPES[invoiceConfig.invoice_subtype]?.label || ''
                  }
                />
                {invoiceConfig.invoice_subtype ===
                  'citizen_personal_certificate' && (
                  <RowText
                    weight="400"
                    label="自然人憑證條碼"
                    value={invoiceConfig.citizen_personal_certificate_code}
                  />
                )}
                {invoiceConfig.invoice_subtype === 'mobile_vehicle' && (
                  <RowText
                    weight="400"
                    label="手機載具"
                    value={invoiceConfig.mobile_vehicle_code}
                  />
                )}
                <RowText
                  weight="400"
                  label="發票收取 Email"
                  value={invoiceConfig.email}
                />
              </InfoBox>
            )}
            {hasInvoiceInfo &&
              invoiceConfig.invoice_type === 'three_copies' && (
                <InfoBox>
                  <h3>發票資訊</h3>
                  <RowText
                    weight="400"
                    label="統一編號"
                    value={invoiceConfig.gui_number}
                  />
                  <RowText
                    weight="400"
                    label="公司抬頭"
                    value={invoiceConfig.company_title}
                  />
                  <RowText
                    weight="400"
                    label="發票收取 Email"
                    value={invoiceConfig.email}
                  />
                </InfoBox>
              )}
            {hasInvoiceInfo && invoiceConfig.invoice_type === 'donate' && (
              <InfoBox>
                <h3>發票資訊</h3>
                <RowText
                  weight="400"
                  label="愛心碼"
                  value={
                    LOVECODE_OPTIONS.find(
                      (x) => x.value === invoiceConfig?.love_code,
                    )?.label || '-'
                  }
                />
                <RowText
                  weight="400"
                  label="發票收取 Email"
                  value={invoiceConfig.email}
                />
              </InfoBox>
            )}
            <Tips>
              ＊根據財政部令「電子發票實施作業要點」，於本網站開立之電子發票會將發票號碼上傳至政府平台，不另寄紙本發票。
            </Tips>
          </Block>
          <Block title={'訂單備註'}>
            <Input.TextArea
              style={{
                height: '90px',
                border: 'solid 1px #E7E7E7',
                borderRadius: '6px',
                resize: 'none',
                boxShadow: 'none',
              }}
              placeholder="請輸入製作備註/加工備註/訂單備註。"
              value={params.note}
              onChange={(e) => setParams({note: e.target.value})}
            />
          </Block>
        </Col>
        <Col flex="400px">
          <OrderReminderWrapper>
            <Block
              title={'訂單付款明細'}
              bg={appConfig.colors.main}
              color="white"
              align="center"
              size="large">
              <PaymentDetails
                calculations={cart.calculations}
                config={config.extraConfig}
                setConfig={setConfig}
                params={params}
                setParams={setParams}
              />
            </Block>
            <LegalNotice>
              <Col style={{padding: 0}}>
                <Checkbox
                  checked={termsAgreed}
                  style={{marginRight: '8px'}}
                  onChange={(e) => {
                    setTermsAgreed(e.target.checked);
                    triggerReminderOnChecked(e.target.checked);
                  }}
                />
                我已閱讀並接受本網站
                <PopupButton onClick={() => setCurrentAgreementModal('terms')}>
                  服務聲明
                </PopupButton>
                、
                <PopupButton
                  onClick={() => setCurrentAgreementModal('print_notice')}>
                  印刷須知
                </PopupButton>
                、<PopupButton>常見問題</PopupButton>
                所有注意事項及條款須知。
              </Col>
            </LegalNotice>
            <LegalNotice>
              <Checkbox
                onChange={(e) => {
                  setParams({is_share_order_photo: e.target.checked});
                }}>
                我同意授權理想印制分享此訂單成品照。
              </Checkbox>
            </LegalNotice>
            <Input.TextArea
              style={{
                height: '78px',
                border: 'solid 1px #E7E7E7',
                borderRadius: '6px',
                resize: 'none',
                boxShadow: 'none',
              }}
              placeholder="歡迎留下您的社群帳號ID或推廣網址，讓理想印制於日後推播分享此訂單印件成品照哦～謝謝"
              onChange={(e) => setParams({share_info: e.target.value})}
            />
            <SubmitButton
              disabled={!termsAgreed}
              onClick={() => handleOnSubmit()}>
              送出訂單
            </SubmitButton>
          </OrderReminderWrapper>
        </Col>
      </Row>
      <CheckoutDetail
        config={config}
        setConfig={setConfig}
        currentEditModal={currentEditModal}
        onCancel={() => setCurrentEditModal(null)}
        logistics={logistics}
      />
      <OrderReminder
        visible={reminderModal}
        onConfirm={() => setReminderModal(false)}
        onCancel={() => handleReminderOnCancle()}
      />
      <CheckoutAgreement
        currentAgreementModal={currentAgreementModal}
        onConfirm={() => setCurrentAgreementModal(null)}
      />
    </Fragment>
  );
}

const Wrap = styled.div`
  display: block;
  background-color: #fafafa;
  padding: 16px 26px;
  border-radius: 10px;
  margin-bottom: 14px;
  ${(props) => props.css}
`;

const StyledButton = styled(Button)`
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  background-color: ${appConfig.colors.main};
  border-radius: 5px;
  color: #fafafa;
  text-align: center;
  border: 1px solid transparent;
  padding: 0 12px;
  &:hover {
    border: 1px solid ${appConfig.colors.main};
  }
`;

const StyledSelect = styled(Select)`
  font-size: 14px;
  color: #505050;
  width: 200px;
  height: 40px;
  border-radius: 5px;
  border: solid 1px #ccc;
  .ant-select-selector {
    border: none;
  }
  @media (max-width: 768px) {
    flex: 1;
    max-width: 100%;
  }
`;

const Tips = styled.p`
  font-size: 12px;
  line-height: 1.75;
  color: #505050;
  margin-bottom: 0;
`;

const LegalNotice = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 14px;
  p {
    margin-bottom: 0;
    font-size: 14px;
    color: #505050;
  }
`;

const SubmitButton = styled(Button)`
  width: 100%;
  height: 50px;
  background-color: ${appConfig.colors.main};
  font-size: 18px;
  font-weight: 500;
  color: white;
  border-radius: 6px;
  margin-top: 14px;
  &[disabled] {
    background-color: #ccc;
    color: white;
    &:hover {
      background-color: #ccc;
      color: white;
    }
  }
`;

const InfoBox = styled.div`
  h3 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    color: #505050;
  }
`;

const PopupButton = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${appConfig.colors.main};
  cursor: pointer;
`;

const StyledSelectContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

const OrderReminderWrapper = styled.div`
  position: sticky;
  top: 74px;
  padding-bottom: 20px;
`;
